<template lang="html">
  <div id="home-page">

    <section class="banners-section">
      <swiper :options="bannersOptions">
        <swiper-slide v-for="(banner, index) in banners" :key="index">
          <img :src="banner.image">
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="container oversized-container news-section">
      <div class="row">
        <div class="col-lg-12 col-xl-5 col-posts">
          <div class="col-12 px-0 col-title">
            <h4>
              Noticias
              <router-link class="btn-more" to="/noticias">Ver todas</router-link>
            </h4>
          </div>

          <router-link class="row blog_row-n-post" :to="'/noticias/'+noticia.id" v-for="(noticia, index) in noticias" :key="index">
            <div class="col box-img">
              <div class="img" v-bind:style="{ backgroundImage: 'url('+noticia.image+')' }">
              </div>
            </div>
            <div class="col col-text">
              <h5 class="title">{{ noticia.titulo }}</h5>
              <h6 class="date">{{ noticia.fecha }}</h6>
              <div class="short-descr" v-html="noticia.cuerpo"></div>

              <router-link class="btn-more" :to="'/noticias/'+noticia.id">Continuar leyendo</router-link>
            </div>
          </router-link>
        </div>

        <div class="col-xl-6 offset-xl-1 col-img">
          <div class="col-12 px-0 text-center col-title">
            <h4>Newsletter</h4>
          </div>

          <img src="public/images/pages/home/n-image.jpg">
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      banners: [
        { image: 'public/images/pages/home/banner-1.jpg', imageMob: 'public/images/pages/home/banner-1-m.jpg' },
        { image: 'public/images/pages/home/banner-1.jpg', imageMob: 'public/images/pages/home/banner-1-m.jpg' }
      ],

      noticias: [
        { id: 2, image: 'public/images/pages/news/image-1.jpg', titulo: 'Productos nuevos desarrollados para mejorar la utilidad de tus servicios.', fecha: '07/01-2021', cuerpo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sem quam, malesuada non ultricies vitae, malesuada nec turpis.' },
        { id: 3, image: 'public/images/pages/news/image-2.jpg', titulo: 'Productos nuevos desarrollados para mejorar la utilidad de tus servicios.', fecha: '07/01-2021', cuerpo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sem quam, malesuada non ultricies vitae, malesuada nec turpis.' },
      ],

      // == Carousels options ==
      bannersOptions: {
        loop: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      // == ==
    }
  }
}
</script>
