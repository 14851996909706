<template lang="html">
  <header id="header">
    <div class="header-content">

      <div class="container oversized-container header-top">
        <div class="row">
          <div class="col col-logo">
            <router-link to="/">
              <img class="img" src="public/images/shared/logo.svg">
            </router-link>
          </div>

          <div class="col col-extra">
            <div class="row align-items-center">
              <div class="col col-search" v-bind:class="{ show: isSearchActive }">
                <a class="btn-close" @click="isSearchActive = !isSearchActive"><i class="fas fa-times"></i></a>

                <b-form @submit="onSubmit">
                  <b-form-group>
                    <b-form-input v-model="search.keywords" type="text" required placeholder="Buscar producto"></b-form-input>
                  </b-form-group>

                  <b-button type="submit" class="btn-search"></b-button>
                </b-form>
              </div>
              <div class="col col-info">
                <h6>Atención a clientes</h6>
                <p>
                  33 3333 3333<br />
                  contacto@dominio.com
                </p>
              </div>

              <div class="col col-user">
                <p class="mb-3">
                  <router-link to="/cart" class="btn-cart">Mi carrito</router-link>
                </p>
                <div class="box-user">
                  <a class="_btn" @click="openLogingModal" v-if="!$parent.logged"><i class="far fa-user"></i> Iniciar sesión</a>
                  <router-link class="_btn" to="/usuario" v-else>Mi cuenta</router-link>
                  <router-link class="_btn" to="/login">Regístrate</router-link>
                </div>
                <p>

                </p>
              </div>
            </div>
          </div>

          <div class="col col-mobile">
            <a class="btn-mobile btn-search" v-bind:class="{ 'invisible ': isSearchActive }" @click="isSearchActive = !isSearchActive"><i class="fas fa-search"></i></a>
            <a class="btn-mobile" @click="isMenuActive = !isMenuActive"><i class="fas fa-bars"></i></a>
          </div>
        </div>
      </div>

      <!-- Menu -->
      <div class="header-menu" @click="isMenuActive = false" v-bind:class="{ show: isMenuActive }">
        <div class="menu-logo">
          <router-link to="/">
            <img src="public/images/shared/logo-white.svg" alt="">
          </router-link>
        </div>

        <div class="menu">
          <div class="row">
            <div class="mobile">
              <router-link class="btn-menu" to="/cart"><i class="fas fa-shopping-cart"></i> Mi carrito</router-link>
              <a class="btn-menu" @click="openLogingModal" v-if="!$parent.logged"><i class="fas fa-user"></i> Iniciar sesión</a>
              <router-link class="btn-menu" to="/usuario" v-else>Mi cuenta</router-link>
              <router-link class="btn-menu" to="/registro"><i class="fas fa-user-plus"></i> Regístrate</router-link>
              <div class="hr">
                <hr />
              </div>
            </div>
            <!-- <router-link class="btn-menu" to="">Productos</router-link> -->
            <a class="btn-menu btn-products-options" @click="isMobMenuActive = !isMobMenuActive; menuSelect = 'categorias';">
              <span><i class="fas fa-bars icon"></i></span> <span class="txt">Categorías</span>
            </a>
            <router-link class="btn-menu" to="">Productos</router-link>

            <!-- <a class="btn-menu" @click="isMobMenuActive = !isMobMenuActive; menuSelect = 'marcas';">Marcas</a> -->
            <router-link class="btn-menu" to="/marcas">Marcas</router-link>
            <router-link class="btn-menu" to="/productos/promociones-11">Promociones</router-link>
            <router-link class="btn-menu" to="/productos/kits-29">Kits</router-link>
            <router-link class="btn-menu" to="/productos/lanzamientos-30">Lanzamientos</router-link>
            <!-- <router-link class="btn-menu" to="/productos/tratamientos-10">Tratamientos</router-link> -->
            <router-link class="btn-menu" to="/pedido-express">Pedido express</router-link>
            <router-link class="btn-menu" to="/noticias">Noticias</router-link>
            <!-- <router-link class="btn-menu" to="">Acerca de</router-link>
            <router-link class="btn-menu" to="">Contacto</router-link> -->
          </div>
        </div>
      </div>
      <!--  -->

      <!-- Submenus -->
      <div class="header-submenu" v-bind:class="{ active: isMobMenuActive }" @click="isMobMenuActive = false">
        <a class="btn-close" @click="isMobMenuActive = false"></a>

        <div class="menu-logo">
          <router-link to="/">
            <img src="public/images/shared/logo-white.svg">
          </router-link>
        </div>

        <div class="menu">
          <!-- categorias -->
          <div class="row" v-if="menuSelect == 'categorias'">
            <div class="box-title">
              <h5>Categorías</h5>
            </div>
            <!-- <router-link class="btn-menu" to="">Productos</router-link> -->
            <div class="links-container">
              <router-link class="btn-menu" :to="'/productos/' + $root._converToURL(categoria.nombre,categoria.id)" v-for="(categoria, index) in categorias" :key="index">{{ categoria.nombre }}</router-link>
            </div>
          </div>
          <!-- fin categorias -->

          <!-- marcas -->
          <div class="row" v-else>
            <div class="box-title">
              <h5>Marcas</h5>
            </div>
            <div class="links-container">
              <router-link class="btn-menu" :to="'/marcas/' + $root._converToURL(marca.nombre, marca.id)" v-for="(marca, index) in marcas" :key="index">{{ marca.nombre }}</router-link>
            </div>
          </div>
          <!-- fin marcas -->

        </div>
      </div>

    </div>

    <!-- Modal login -->
    <b-modal no-close-on-backdrop no-close-on-esc id="modal-login" ref="modal-login" hide-footer title="Ingresa tu cuenta">
      <b-form @submit="login">

        <b-form-group
          class="form-group-login"
          label="Correo:"
          label-for="i-email">
          <b-form-input
            id="i-email" class="i-email"
            v-model="formLogin.email"
            type="email"
            placeholder="correo@ejemplo.com"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          class="form-group-login"
          label="Contraseña:"
          label-for="i-password">
          <b-form-input
            id="i-password" class="i-password"
            v-model="formLogin.password"
            type="password"
            placeholder="●●●●●●●●●●●"
            required
          ></b-form-input>
        </b-form-group>

        <p class="pt-2 mb-4">
          <a @click="openRecoverModal">Olvidé mi contraseña</a>
        </p>

        <p class="text-left">
          <b-button type="submit" class="btn-login">Iniciar sesión</b-button>
        </p>

      </b-form>
    </b-modal>
    <!--  -->

    <!-- Modal remover password -->
    <b-modal no-close-on-backdrop no-close-on-esc id="modal-login" ref="modal-recover" hide-footer title="Recuperar contraseña">
      <b-form @submit="login">
        <p class="mb-4">
          Escriba su correo electrónico. Le enviaremos la información necesaria para recuperar el acceso a su cuenta.
        </p>

        <b-form-group
          class="form-group-login"
          label="Correo de recuperación:"
          label-for="i-recover">
          <b-form-input
            id="i-recover" class="i-email"
            v-model="formRecover.email"
            type="email"
            placeholder="correo@ejemplo.com"
            required
          ></b-form-input>
        </b-form-group>

        <p class="pt-2 mb-4 text-left">
          <b-button type="submit" class="btn-login">Recuperar cuenta</b-button>
        </p>

      </b-form>
    </b-modal>
    <!--  -->
  </header>
</template>

<script>
export default {
  data(){
    return{
      isMenuActive: false,
      isMobMenuActive: false,
      isSearchActive: false,
      menuSelect: 'categorias',
      tel: null,
      email: '',

      formLogin: {
        email: null,
        password: null
      },

      formRecover: {
        email: null
      },

      search: {
        keywords: null
      },
      categorias: [],
      marcas: []
    }
  },

  methods: {
    getCategorias(){
      axios.get(tools.url('/categorias')).then((response)=>{
        this.categorias = response.data;
      }).catch((error)=>{
        console.log(error);
      });
    },

    getMarcas(){
      axios.get(tools.url('/marcas')).then((response)=>{
        this.marcas = response.data;
      }).catch((error)=>{
        console.log(error);
      });
    },

    openLogingModal(){
      this.$refs['modal-login'].show()
    },
    openRecoverModal(){
      this.$refs['modal-login'].hide()
      this.$refs['modal-recover'].show()
    },

    onSubmit(){
      //evt.preventDefault()
      this.$router.push({path: '/busqueda', query: {keywords:this.search.keywords}});
    },

    login(evt){
      evt.preventDefault()

      axios.post(tools.url('api/cliente/login'), this.formLogin)
      .then((response) => {
        this.$parent.user = response.data.user;
        this.$parent.token = response.data.token;
        localStorage.setItem("tokenFront",this.$parent.token);
        this.$parent.logged = true;

        this.$router.push( { path: "/usuario"} );
        this.$refs['modal-login'].hide()
      })
      .catch((error) => {
        //this.formErrors.push("Credenciales incorrectas");
      });

    },

    getFooter(){
      axios.get(tools.url('/shared/footer')).then((response)=>{
        this.tel = response.data.tel;
        this.email = response.data.email;
      }).catch((error)=>{
        console.log(error);
      });
    }
  },

  mounted(){
    this.getCategorias();
    this.getMarcas();
    this.getFooter();
  },

  watch: {
    $route(to, from) {
      this.isMobMenuActive = false;
      this.isMobMenuBrandActive = false;
    },
  }
}
</script>
