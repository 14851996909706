<template lang="html">
	<footer id="footer">
		<div class="container oversized-container">
			<div class="row align-items-center">

				<div class="col-12 col-lg-3 col-footer col-logo">
					<router-link to="/">
						<img class="logo" src="public/images/shared/logo.svg">
					</router-link>
				</div>

				<div class="col-12 col-lg">
					<div class="row align-items-center">
						<div class="col-6 col-md-4 col-lg col-footer">
							<h6 class="title">Mapa del sitio</h6>

							<p class="f-w-600">
								<!-- <router-link to="">Lo + nuevo</router-link><br />
								<router-link to="/productos/promociones-11">Promociones</router-link><br />
								<router-link to="/marcas/">Marcas</router-link><br />
								<router-link to="/productos/tratamientos-10">Tratamientos</router-link><br />
								<router-link to="">Protectores solares</router-link><br /> -->

								<router-link to="">Lo más nuevo</router-link><br />
								<router-link to="">Promociones</router-link><br />
								<router-link to="">Marcas</router-link><br />
								<router-link to="">Categorías</router-link><br />
								<router-link to="">Grabados</router-link>
							</p>
						</div>

						<div class="col-6 col-md-4 col-lg col-footer">
							<p class="f-w-600">
								<router-link to="">Maquila</router-link><br />
								<router-link to="">Preguntas frecuentes</router-link><br />
								<router-link to="">Nosotros</router-link><br />
								<router-link to="">Contacto</router-link><br />
							</p>
						</div>

						<div class="col-md-4 col-lg offset-xl-1 col-footer">
							<p class="f-w-600">
								Atención a clientes 24/7
							</p>
							<p class="mb-3 txt-gray">
								{{ tel }}<br />
								{{ email }}
							</p>

							<p class="t-d-u f-w-600">
								<router-link to="/aviso-privacidad">Aviso de Privacidad</router-link><br />
								<router-link to="/terminos-condiciones">Términos y condiciones</router-link><br />
							</p>
						</div>
						<div class="col-lg col-footer col-warranty">
							<img class="img-fluid" src="public/images/shared/garantia.png" alt="">
						</div>
					</div>
				</div>

			</div>
		</div>
	</footer>
</template>


<script>
export default {
  data(){
    return{
	  email: 'contanto@example.com',
	  tel: '33 00 00 00'
    }
  },

  methods: {
	getFooter(){
		axios.get(tools.url('/shared/footer')).then((response)=>{
			this.tel = response.data.tel;
			this.email = response.data.email;
		}).catch((error)=>{
			console.log(error);
		});
	}
  },

  mounted(){
    this.getFooter();
  }
}
</script>
